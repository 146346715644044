<template>
  <div class="episode-page" v-if="this.$store.state.podcast.singleEpisodePage">
    <section class="episode-player-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-3 mb-lg-0 mb-4">
            <img
                v-scroll-animation="'animate__zoomIn'"
                class="img-fluid"
                :src="this.$store.state.podcast.singleEpisodePage.image_full_path"
                alt="Episode Image"
            />
          </div>
          <div class="col-lg-9">
            <h2 class="episode-title" v-scroll-animation="'animate__slideInDown'">
              {{ this.$store.state.podcast.singleEpisodePage.title }}</h2>
            <audio-player v-scroll-animation="'animate__slideInDown'"
                          :mp3-link="this.$store.state.podcast.singleEpisodePage.main_mp3_url"/>
            <div class="episode-info">
              <div class="episode-actions" v-scroll-animation="'animate__slideInDown'">
                <div class="action-item bookmark-btn share-btn" @click="toggleSharePopup(true)">
                  <font-awesome-icon icon="share-alt" class=""/>
                </div>
                <div class="action-item bookmark-btn" v-if="$store.getters['user/getToken']"
                     v-bind:class="{'active':$store.state.podcast.singleEpisodePage.is_in_fav}"
                     @click="changeFavStatus($store.state.podcast.singleEpisodePage.is_in_fav)">
                  <font-awesome-icon class="active-icon" icon="heart"/>
                  <font-awesome-icon :icon="['far', 'heart']"/>
                </div>
                <!--                <div class="action-item bookmark-btn">-->
                <!--                  <font-awesome-icon class="active-icon" icon="bookmark"/>-->
                <!--                  <font-awesome-icon :icon="['far', 'bookmark']"/>-->
                <!--                </div>-->
              </div>
              <div class="episode-publish-info">
                <div class="publish-info-item" v-scroll-animation="'animate__slideInDown'">
                  <font-awesome-icon icon="calendar-week"/>
                  <p>{{ this.$store.state.podcast.singleEpisodePage.created_at }}</p>
                </div>
                <div class="publish-info-item" v-scroll-animation="'animate__slideInDown'">
                  <font-awesome-icon icon="clock"/>
                  <p>{{ this.$store.state.podcast.singleEpisodePage.audio_time }}</p>
                </div>
              </div>
            </div>
            <div class="episode-links">
              <listen-platform v-scroll-animation="'animate__zoomIn'" :link="name" :slug="value" v-bind:key="name"
                               v-for="(name,value) in this.$store.state.podcast.singleEpisodePage.listen_urls"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="episode-text-info-section">
      <div class="container">
        <div class="title-part">
          <h2 v-scroll-animation="'animate__slideInDown'">عن الحلقة</h2>
          <div v-scroll-animation="'animate__slideInDown'"
               v-html="this.$store.state.podcast.singleEpisodePage.short_description"></div>
          <!--          <p v-scroll-animation="'animate__slideInDown'">-->
          <!--            {{ this.$store.state.podcast.singleEpisodePage.short_description }}-->
          <!--          </p>-->
        </div>

        <div class="info-group keywords">
          <h5 v-scroll-animation="'animate__slideInDown'">كلمات مفتاحية</h5>
          <router-link :to="{name:'Search',params:{keyword:keyword.name,isPodcast:false}}" v-bind:key="keyword.name"
                       v-scroll-animation="'animate__slideInDown'"
                       v-for="keyword in this.$store.state.podcast.singleEpisodePage.tags_list">
            <span>{{ keyword.name }}</span>
          </router-link>
        </div>
        <div class="info-group" v-if="this.$store.state.podcast.singleEpisodePage.sources_list.length>0">
          <h5 v-scroll-animation="'animate__slideInDown'">المصـــــــــــــــــــــــــــــــادر</h5>
          <a :href="keyword.url" target="_blank" v-bind:key="keyword.title"
             v-scroll-animation="'animate__slideInDown'"
             v-for="keyword in this.$store.state.podcast.singleEpisodePage.sources_list">
            <span>{{ keyword.title }}</span>
          </a>
        </div>
        <div class="more-episodes-part" v-if="this.$store.state.podcast.moreEpisodes.length>0">
          <h3 v-scroll-animation="'animate__slideInDown'">باقي الحلقات</h3>
          <div class="episodes-container">
            <swiper
                dir="rtl"
                ref="mySwiper"
                class="episodes-slider"
                :options="swiperOptions"
            >
              <swiper-slide v-bind:key="episode.id"
                            v-scroll-animation="'animate__zoomIn'"
                            v-for="episode in this.$store.state.podcast.moreEpisodes">
                <div @click="getNewEpisode(episode.id)" class="episode-item">
                  <div class="flip-card-inner">
                    <div class="frontSide">
                      <img :src="episode.image_full_path" alt="Program Image">
                    </div>
                    <div class="backSide">
                      <h4>{{ episode.title }}</h4>
                      <!--                      <p>-->
                      <!--                        {{ program.short_description }}-->
                      <!--                      </p>-->
                    </div>
                  </div>
                  <!--                  <img-->
                  <!--                      :src="episode.image_full_path"-->
                  <!--                      alt="Episode Image"-->
                  <!--                  />-->
                  <!--                  <p>{{ episode.title }}</p>-->
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <section class="share-popup-container" @click="toggleSharePopup()">
      <div class="share-container">
        <ShareNetwork
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{backgroundColor: network.color}"
            :url="episodeUrl"
            :title="$store.state.podcast.singleEpisodePage.title"
            :description="$store.state.podcast.singleEpisodePage.short_description.replace(/(<([^>]+)>)/gi, '')"

        >
          <font-awesome-icon :icon="['fab', network.icon]"/>
          <span>{{ network.name }}</span>
        </ShareNetwork>
      </div>

    </section>
  </div>
</template>

<script>
import AudioPlayer from "@/components/audioPlayer/audioPlayer";
import ListenPlatform from "@/components/smallComponents/listenPlatformItem";
import VueSocialSharing from 'vue-social-sharing';
import ShareNetwork from "vue-social-sharing/src/share-network";

export default {
  name: "episode",
  components: {ListenPlatform, AudioPlayer, VueSocialSharing, ShareNetwork},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 50,
        loop: false,
        centeredSlides: true,
        speed: 500,
        initialSlide: 1,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
      networks: [
        // {network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333'},
        {network: 'facebook', name: 'Facebook', icon: 'facebook', color: '#1877f2'},
        {network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366'},
        // { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
        // { network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
        // {network: 'skype', name: 'Skype', icon: 'skype', color: '#00aff0'},
        // { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
        {network: 'telegram', name: 'Telegram', icon: 'telegram', color: '#0088cc'},
        {network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2'},
      ],
      episodeUrl:'',
    };
  },
  methods: {
    changeFavStatus(isFav) {
      this.$store.commit('podcast/updateEpisodeFavStatus');
      if (isFav) {
        this.$store.dispatch('favorite/removeItemFromFav', {foreign_id: this.$route.params.id, model: 'PodcastPart'})
            .catch(err => {
              this.$store.commit('podcast/updateEpisodeFavStatus');
            });
      } else {
        this.$store.dispatch('favorite/addFavItem', {foreign_id: this.$route.params.id, model: 'PodcastPart'})
            .catch(err => {
              this.$store.commit('podcast/updateEpisodeFavStatus');
            });
      }
    },
    getNewEpisode(id, isFirstTime) {
      this.$store.dispatch("loading/show");
      this.$store
          .dispatch("podcast/getSingleEpisodePageInfo", {
            part_id: id,
          })
          .then(() => {
            if (!isFirstTime) {
              this.$router.push({name: 'Episode', params: {id: id}});
            }
            this.$store.dispatch("loading/hide");

            navigator.mediaSession.metadata = new MediaMetadata({
              title: this.$store.state.podcast.singleEpisodePage.title,
              artist: 'تنوين ميديا',
              album: this.$store.state.podcast.singleEpisodePage.podcast_program.title,
              artwork: [{src: this.$store.state.podcast.singleEpisodePage.image_full_path}]
            });
          })
          .catch((err) => {
            console.log(err.message);
          });
    },
    searchViaTag(tagName) {
      console.log(tagName);
    },
    toggleSharePopup(isBtn = false) {
      // console.log(this.episodeUrl);
      let sharePopup = document.getElementsByClassName("share-popup-container")[0];
      if(isBtn){
        sharePopup.classList.add("active");
        return;
      }
      if (event.target === sharePopup) {
        sharePopup.classList.remove("active");
      }
    },
  },
  created() {
    this.$store.dispatch("loading/show");
    this.getNewEpisode(this.$route.params.id, true);

    this.episodeUrl = document.URL;

  }
};
</script>
<style lang="scss">
.episode-page {
  padding: 5em 0 4em 0;
  background-color: #fbfcfc;

  .episode-player-section {
    margin-bottom: 3em;

    .img-fluid {
      border-radius: 8px;
      box-shadow: 0 0 12px rgba(#000000, 0.1);
      width: 100%;
      object-fit: contain;
      object-position: center;
    }

    .episode-title {
      font-weight: 700;
      color: $myViolet;
    }

    .episode-info {
      margin-top: 1.5em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .episode-actions {
        display: flex;
        align-items: center;

        .action-item {
          margin-inline-end: 0.75em;
          cursor: pointer;

          &:last-of-type {
            margin-inline-end: 0;
          }

          svg {
            font-size: 1.5rem;
            color: $myBlue;

            &.active-icon {
              display: none;
            }
          }

          &.active {
            svg {
              display: none;

              &.active-icon {
                display: block;
                color: $myYellow;
              }
            }
          }
        }
      }

      .episode-publish-info {
        display: flex;
        align-items: center;

        .publish-info-item {
          display: flex;
          align-items: center;
          margin-inline-end: 1.5em;

          &:last-of-type {
            margin-inline-end: 0;
          }

          svg {
            margin-inline-end: 0.5em;
            color: $myViolet;
            font-size: 1.35rem;
          }

          p {
            margin-bottom: 0;
            color: $myBlue;
            font-weight: 700;
            font-size: 1.1rem;
          }
        }
      }
    }

    .episode-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.3em;

      .link-item {
        display: inline-block;
        color: $myBlue;
        font-weight: 700;
        margin-inline-start: 1em;
        font-size: 0.95rem;

        &:last-of-type {
          margin-inline-end: 0em;
        }

        img {
          width: 2.5em;
          height: 1.5em;
          object-fit: contain;
          object-position: center;
          margin-inline-start: 0.32em;
        }
      }
    }
  }

  .episode-text-info-section {
    margin-top: 5em;

    .container {
      padding: 3.5em 2em;
      background-color: #f5f6f7;
    }

    .title-part {
      margin-bottom: 2em;

      h2 {
        font-weight: 700;
      }

      p {
        font-size: 1.1rem;
      }
    }

    .info-group {
      margin-bottom: 2em;

      &.keywords {
        h5 {
          margin-bottom: 0.5em;
        }

        a {
          display: inline-block;
          margin-inline-end: 0.5em;
          border: 1px solid $myBlue;
          border-radius: 1em;
          padding: 0.05em 1em;
          transition: 0.3s ease-in-out;

          &:hover {
            background-color: $myBlue;
            color: #ffffff;
          }
        }
      }

      h5 {
        color: $myViolet;
        font-weight: 700;
        font-size: 1.4rem;
      }

      p,
      a {
        color: $myBlue;
        display: block;
      }
    }

    .more-episodes-part {
      margin-top: 5em;

      h3 {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0em;
      }

      .episodes-container {
        overflow: hidden;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        padding: 3.5em 0;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
        .episode-item {
          display: block;
          cursor: pointer;
          height: 22em;
          perspective: 50em;

          .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.8s;
            transform-style: preserve-3d;
            border-radius: 8px;
            box-shadow: 0 0 12px rgba(#000000, 0.05);
          }

          &:hover {
            .flip-card-inner {
              transform: rotateY(180deg);
            }
          }

          .frontSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            /* Style the front side (fallback if image is missing) */
            background-color: #bbb;
            color: black;
            border-radius: 8px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
              border-radius: 8px;
            }

          }

          .backSide {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden; /* Safari */
            backface-visibility: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 3em 2.5em;
            background-color: #fff;
            transform: rotateY(180deg);
            border-radius: 8px;
          }

          img {
            border-radius: 8px;
            width: 100%;
            margin-bottom: 0.75em;
            box-shadow: 0 0 12px rgba(#000000, 0.1);
          }

          h4 {
            color: $myViolet;
            font-weight: 700;
            font-size: 1.3rem;
            text-align: center;
          }
        }
      }
    }
  }

  .share-popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 100002;
    background-color: rgba(#000000, 0.6);

    &.active {
      display: flex;
    }

    .share-container {
      background-color: #ffffff;
      padding: 1em 1.5em;
      padding-bottom: 0.5em;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 0.5em;
      max-width: 90%;

      a {
        border-radius: 4px;
        padding: 0.25em 0.5em;
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;

        svg {
          margin-inline-end: 0.5em;
          color: #ffffff;
        }

        span {
          color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .episode-page {
    .episode-player-section {
      .episode-title {
        text-align: center;
      }

      .episode-links {
        margin-top: 2em;
        justify-content: space-around;
        flex-wrap: wrap;

        .link-item {
          margin-bottom: 1em;
        }
      }
    }

    .episode-text-info-section {
      .info-group.keywords {
        a {
          margin-bottom: 0.5em;
        }
      }
    }
  }
}
</style>

