<template>
  <div class="program-page" v-if="this.$store.state.podcast.programPage">
    <section class="hero-episode-section">
      <div class="container justify-content-center">
        <div class="play-episode-part">
          <img
              :src="isMobile()? this.$store.state.podcast.programPage.program.image_full_path:episodeInfo.image"
              alt="player background"
              class="player-background"
          />
          <div class="audio-player" v-scroll-animation="'animate__slideInUp'" v-if="episodeInfo.mp3Link">
            <audio-player :mp3-link="episodeInfo.mp3Link"/>
          </div>
        </div>
        <div class="episode-info">
          <h2 v-scroll-animation="'animate__slideInDown'">{{ episodeInfo.title }}</h2>
          <p v-scroll-animation="'animate__slideInDown'">
            {{ episodeInfo.description }}
          </p>
        </div>
        <div class="listen-other-platforms col-lg-10">
          <h3 v-scroll-animation="'animate__slideInDown'">الاستماع للبرنامج عبر منصات البودكاست</h3>
          <div class="other-platforms-container">
            <listen-platform
                v-scroll-animation="'animate__zoomIn'"
                :slug="value"
                :link="name"
                v-bind:key="value"
                v-for="(name, value) in episodeInfo.listenPlatforms"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="playOnTheGoodPlatform-section">
      <div
          class="container"
          v-html="this.$store.state.podcast.programPage.program.podcast_iframe"
          v-scroll-animation="'animate__zoomIn'"
      >
      </div>
    </section>
    <section class="other-episodes-section">
      <div class="container">
        <h2 v-scroll-animation="'animate__slideInDown'">كافة الحلقات</h2>
        <swiper
            class="episodes-slider"
            dir="rtl"
            ref="mySwiper"
            :options="swiperOptions"
        >
          <swiper-slide
              v-scroll-animation="'animate__zoomIn'"
              class="episode-item"
              v-bind:key="episode.id"
              v-for="episode in this.$store.state.podcast.programPage.parts"
          >
            <router-link
                :to="{
                name: 'Episode',
                params: { id: episode.id },
              }"
            >
              <img :src="episode.image_full_path" alt="Episode Image"/>
              <h5>
                {{ episode.title }}
              </h5>
              <div class="episode-info">
                <div class="info-item">
                  <font-awesome-icon icon="stopwatch"/>
                  <p>{{ episode.audio_time }}</p>
                </div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </section>
  </div>
</template>

<script>
import AudioPlayer from "@/components/audioPlayer/audioPlayer";
import ListenPlatform from "@/components/smallComponents/listenPlatformItem";

export default {
  name: "program",
  components: {ListenPlatform, AudioPlayer},
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 3,
        spaceBetween: 50,
        loop: false,
        centeredSlides: true,
        speed: 500,
        initialSlide: 1,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          220: {
            slidesPerView: 1,
          },
          767: {
            slidesPerView: 2,
            centeredSlides: false,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
      episodeInfo: {
        title: "",
        image: "",
        mp3Link: "",
        description: "",
        listenPlatforms: [],
      },
    };
  },

  methods: {
    isMobile() {
      return window.matchMedia("(max-width: 576px)").matches;
    },
  },
  created() {
    this.$store.dispatch("loading/show");
    this.$store
        .dispatch("podcast/getProgramInfo", {program_id: this.$route.params.id})
        .then(() => {
          this.episodeInfo.title =
              this.$store.state.podcast.programPage.program.title;
          this.episodeInfo.description =
              this.$store.state.podcast.programPage.program.short_description;
          this.episodeInfo.image =
              this.$store.state.podcast.programPage.program.cover_full_path;
          this.episodeInfo.listenPlatforms =
              this.$store.state.podcast.programPage.program.listen_urls;
          this.episodeInfo.mp3Link =
              this.$store.state.podcast.programPage.program.main_mp3_url;
          this.$store.dispatch("loading/hide");
        })
        .catch((err) => {
          console.log(err.message);
        });
  },
};
</script>

<style lang="scss">
.program-page {
  padding: 4em 0;
  background: #fbfcfc;

  .hero-episode-section {
    .container {
      display: flex;
      flex-wrap: wrap;
    }

    .play-episode-part {
      width: 100%;
      height: 25em;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 0 12px rgba(#000000, 0.1);

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
      }

      .audio-player {
        position: relative;
        z-index: 3;
        width: 100%;
        background: linear-gradient(0deg, rgba(#ffffff, 0.8), transparent);
        padding: 2.5em 2em 1.5em 2em;
      }
    }

    .episode-info {
      margin-top: 1.5em;
      width: 100%;

      h2 {
        font-weight: 700;
        color: $myViolet;
      }

      p {
        font-size: 1.2rem;
      }
    }

    .listen-other-platforms {
      margin-top: 3em;

      h3 {
        text-align: center;
        font-size: 2rem;
      }

      .other-platforms-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0.75em;

        a {
          display: block;
          margin-inline-start: 1em;
          color: #323232;
          font-weight: 700;

          img {
            height: 2em;
            width: 2em;
            object-fit: contain;
            object-position: center;
            margin-inline-start: 0.42em;
          }
        }
      }
    }
  }

  .playOnTheGoodPlatform-section {
    margin-top: 2em;
    padding: 3em 0 20em 0;
    background-color: #f5f6f7;

    iframe {
      width: 100%;
      height: 30em;
      border-radius: 8px;
      overflow: hidden;
    }
  }

  .other-episodes-section {
    margin-top: -14em;
    padding-bottom: 2em;

    h2 {
      margin-bottom: 1.5em;
    }

    .episodes-slider {
      overflow: hidden;
      padding: 1em 2em;

      .episode-item {
        padding: 1.5em 1em;
        border-radius: 8px;
        box-shadow: 0 0 12px rgba(#000000, 0.1);
        overflow: hidden;
        background-color: #ffffff;
        transition: all 0.5s ease-in-out !important;

        &:hover {
          transform: scale(1.05);
        }

        a {
          display: block;
        }

        img {
          width: 100%;
          height: 20em;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }

        h5 {
          margin-top: 1em;
          color: $myViolet;
          font-size: 1.5rem;
          font-weight: 700;
        }

        .episode-info {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-top: 1em;

          .info-item {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            svg {
              margin-inline-end: 0.5em;
              color: $myYellow;
            }

            p {
              margin-bottom: 0;
              font-weight: 700;
              color: $myBlue;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .program-page {
    .hero-episode-section {
      .play-episode-part {
        height: auto;
        aspect-ratio: 1/1;
        .audio-player {
          padding: 1.5em 0.75em;
          filter: drop-shadow(0 0 12px rgba(#000000,0.15));
        }
      }

      .episode-info {
        p {
          font-size: 1.1rem;
          text-align: justify;
        }
      }

      .listen-other-platforms {
        h3 {
          font-size: 1.65rem;
        }

        .other-platforms-container {
          margin-top: 1.2em;

          a {
            margin-bottom: 0.75em;
          }
        }
      }
    }

    .other-episodes-section {
      h2 {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1em;
      }
      .episodes-slider{
        .episode-item{
          img{
            height: auto;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
}
</style>
